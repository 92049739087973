import React, {useEffect, useState} from "react"
// MUI
import {Alert, Avatar, Box, Button, Collapse, Divider, Grid, Paper, Typography} from "@mui/material";
import {FilterAlt, FilterAltOff, RememberMeOutlined} from "@mui/icons-material";
// Translation
import {useTranslation} from "react-i18next";
// Queries
import {useLazyQuery} from "@apollo/client";
import {APIPost, GET_VCARDS} from "../../../api/calls";
// Components
import {Paginator, TextField} from "../../utils";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {styles} from "../VcardPanel/styles";
import {useUser} from "../../../provider/UserProvider";

const {REACT_APP_DASHBOARD_API_URL} = process.env

export default function StatsPanel(props: any) {
    // return (<></>)

    const {t} = useTranslation(["common"])
    const {isMember} = props
    const {user, token} = useUser()

    // Vcards state
    const [vcards, setVcards] = useState<any | undefined>([])
    const [vcardsAnalytics, setVcardsAnalytics] = useState<any>([])
    // Filters
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [perPage, setPerPage] = useState<number>(10)
    const [isLastPage, setIsLastPage] = useState<boolean>(false)
    // const [filters, setFilters] = useState<any>({
    //     name: "",
    //     department: "",
    //     template: "",
    //     email: ""
    // })

    // GQL calls
    const [getVcardsList, {
        data: vcardsListData,
        // loading: vcardsListLoading,
    }] = useLazyQuery(GET_VCARDS, {
        fetchPolicy: "no-cache"
    })

    // Vanilla calls
    const fetchAnalytics: any = async (vcard: any) => {

        // let vcardAnalytics = vcard
        let count = 0

        const body = {
            filters: [
                {
                    field: "QR Codes",
                    operator: "is_one_of",
                    value: [
                        vcard.uuid
                    ]
                }
            ],
            time_range: {
                type: 1,
                number: 2,
            }
        }

        await APIPost(REACT_APP_DASHBOARD_API_URL + "/analytics/vcards_pro/search", body, token).then((res: any) => {
            if (!!res.parsedBody) count = res.parsedBody.visits_count
        })
        return count
    }

    // Lifecycles
    useEffect(() => {
        if (user) {
            // First, fetch vcards
            fetchVcards()
        }
        return
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    useEffect(() => {
        if (vcardsListData) {
            if (isMember) {
                setVcards(vcardsListData.getVcards.values.filter((vcard: any) => (vcard.user.email === user.email || vcard.ownerUuid === user.uuid)))
                return
            }
            setVcards(vcardsListData.getVcards.values)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vcardsListData])

    useEffect(() => {
        if (vcardsListData) {
            let vcardsDisplayed = pageNumber * perPage
            if (vcardsListData.getVcards.total === undefined || vcardsDisplayed >= vcardsListData.getVcards.total) {
                setIsLastPage(true)
            } else {
                setIsLastPage(false)
            }
        }
    }, [pageNumber, vcardsListData, perPage])

    useEffect(() => {
        if (!!vcards) {
            delayedFetch().then()
        }
    }, [vcards])

    const delayedFetch = async () => {
        let analytics: any[] = []
        for (const _vcard of vcards) {
            let count = await fetchAnalytics(_vcard)
            analytics.push({
                ..._vcard,
                visits: count
            })
        }
        setVcardsAnalytics(analytics)
    }

    useEffect(() => {
        fetchVcards()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, perPage])

    // logic
    const fetchVcards = () => {
        getVcardsList({
            variables: {
                page: pageNumber,
                perPage: perPage,
            }
        }).then()
    }

    // renders
    const renderStatsTable = () => {

        // Data table content
        const dataTablePhotoBody = (rowData: any) => {
            if (!rowData) return <></>
            return <Avatar src={rowData.user.picture} sx={styles.avatar}><RememberMeOutlined /></Avatar>
        }

        const dataTableNameAndPermissionsBody = (rowData: any) => {
            if (!rowData || (!rowData.user.firstName && !rowData.user.lastName)) return <></>
            return (
                <>
                    <Typography variant={"body2"}>
                        {rowData.user.firstName} {rowData.user.lastName}
                    </Typography>
                </>
            )
        }

        const dataTableVisitsCount = (rowData: any) => {
            if (!rowData) return <></>
            return (
                <>
                    <Typography variant={"body2"}>
                        {rowData.visits}
                    </Typography>
                </>
            )
        }


        return (
            <>
                <DataTable
                    value={vcardsAnalytics}
                    breakpoint={"1103px"}
                    emptyMessage={t("vcards_list_empty_message")}
                    rows={10}
                    sortField={"user.lastName"}
                >
                    <Column
                        header={t("vcard_photo")}
                        body={dataTablePhotoBody}
                    ></Column>
                    <Column
                        body={dataTableNameAndPermissionsBody}
                        header={t("name")}
                        // sortable
                        // sortField={"user.lastName"}
                    ></Column>
                    <Column
                        body={dataTableVisitsCount}
                        header={t("visits_count")}
                    ></Column>
                </DataTable>
            </>
        )
    }

    // Filters
    // const renderFiltersSection = () => {
    //
    //     const returnClearFiltersButton = () => {
    //         return (
    //             <Button
    //                 onClick={() => clearFilters()}
    //                 startIcon={<FilterAltOff />}
    //                 color={"secondary"}
    //                 variant={"contained"}
    //                 disableElevation
    //                 size={"small"}
    //             >
    //                 {t("clear_filters")}
    //             </Button>
    //         )
    //     }
    //
    //     if (!vcardsListLoading) {
    //         return (
    //             <Grid item xs={12}>
    //                 {/*<Collapse in={true}>*/}
    //                 {/*    <Grid container justifyContent={"flex-start"} spacing={1} columns={14}>*/}
    //                 {/*        <Grid item xs={14}>*/}
    //                 {/*            {t("filters_section")}*/}
    //                 {/*        </Grid>*/}
    //                 {/*        <Grid item xs={12} md={6} lg={3}>*/}
    //                 {/*            <TextField*/}
    //                 {/*                fullWidth*/}
    //                 {/*                size={"small"}*/}
    //                 {/*                label={t("name")}*/}
    //                 {/*                value={filters.name}*/}
    //                 {/*                onChange={(e: any) => setFilters({...filters, name: e.target.value})}*/}
    //                 {/*            />*/}
    //                 {/*        </Grid>*/}
    //                 {/*        <Grid item xs={12} md={6} lg={3}>*/}
    //                 {/*            <TextField*/}
    //                 {/*                fullWidth*/}
    //                 {/*                size={"small"}*/}
    //                 {/*                label={t("email")}*/}
    //                 {/*                value={filters.email}*/}
    //                 {/*                onChange={(e: any) => setFilters({...filters, email: e.target.value})}*/}
    //                 {/*            />*/}
    //                 {/*        </Grid>*/}
    //                 {/*        <Grid item xs={14} md={2} textAlign={"right"}>*/}
    //                 {/*            <Button*/}
    //                 {/*                onClick={() => filter()}*/}
    //                 {/*                startIcon={<FilterAlt />}*/}
    //                 {/*                size={"small"}*/}
    //                 {/*                variant={"contained"} disableElevation*/}
    //                 {/*            >*/}
    //                 {/*                {t("filter")}*/}
    //                 {/*            </Button>*/}
    //                 {/*        </Grid>*/}
    //                 {/*        <Grid item xs={14}>*/}
    //                 {/*            <Collapse in={!!filters.name || !!filters.email || !!filters.department || !!filters.template}>*/}
    //                 {/*                <Alert severity={"info"} action={returnClearFiltersButton()} sx={{ py: 2 }}>*/}
    //                 {/*                    {t("filters_are_active")}*/}
    //                 {/*                </Alert>*/}
    //                 {/*            </Collapse>*/}
    //                 {/*        </Grid>*/}
    //                 {/*    </Grid>*/}
    //                 {/*</Collapse>*/}
    //             </Grid>
    //         )
    //     }
    // }

    // const clearFilters = () => {
    //     setFilters({
    //         name: "",
    //         department: "",
    //         template: "",
    //         email: ""
    //     })
    //
    //     fetchVcards()
    // }

    // const filter = () => {
    //
    //     let query: any = {}
    //     let filtersLength: number = 0
    //     let activeFilters: any = {}
    //
    //     Object.keys(filters).forEach((key: any) => {
    //         if (filters[key] !== "") {
    //             filtersLength++
    //             activeFilters = {...activeFilters, [key]: filters[key]}
    //         }
    //     })
    //
    //     const returnDataKey = (dataName: any) => {
    //         switch (dataName) {
    //             case "name": return "user.fullName"
    //             case "department": return "departmentUuid"
    //             case "template": return "templateUuid"
    //             case "email": return "user.email"
    //         }
    //     }
    //
    //     const returnOperator = (dataName: any) => {
    //         switch (dataName) {
    //             case "department": return "Eq"
    //             case "template": return "Eq"
    //             default: return "Contains"
    //         }
    //     }
    //
    //     if (filtersLength === 0 || !activeFilters) {
    //         clearFilters()
    //         return
    //     } else if (filtersLength === 1) {
    //         query = {
    //             type: "Value",
    //             value: {
    //                 type: returnOperator(Object.keys(activeFilters)[0]),
    //                 key: returnDataKey(Object.keys(activeFilters)[0]),
    //                 value: Object.values(activeFilters)[0]
    //             }
    //         }
    //     } else if (filtersLength > 1) {
    //
    //         let _and: any[] = []
    //
    //         Object.keys(activeFilters).forEach((key: any) => {
    //             _and.push({
    //                 type: "Value",
    //                 value: {
    //                     type: returnOperator(key),
    //                     key: returnDataKey(key),
    //                     value: activeFilters[key]
    //                 }
    //             })
    //         })
    //
    //         query = {
    //             type: "And",
    //             and: _and
    //         }
    //     }
    //
    //     getVcardsList({
    //         variables: {
    //             page: 1,
    //             perPage: perPage,
    //             filter: query
    //         }
    //     }).then()
    //
    // }


    return (
        <Box sx={{ p: 2 }}>
            <Grid container justifyContent={{ xs: "center" }} rowSpacing={3} columnSpacing={1}>
                <Grid item xs={12}>
                    <Typography variant={"h4"} component={"p"}>
                        {t("vcards_stats")}
                    </Typography>
                    <Typography>
                        {t("vcards_stats_intro")}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Divider />
                </Grid>

                {/*{renderFiltersSection()}*/}

                <Grid item xs={"auto"} md={12}>
                    <Paper>
                        {renderStatsTable()}
                    </Paper>
                </Grid>

                <Paginator {...{perPage, setPerPage, pageNumber, setPageNumber, isLastPage, t}} />
            </Grid>
        </Box>
    )
}
